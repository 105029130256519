<template>
  <v-card
    @click.stop="execute(type, action)"
    :class="`${
      $vuetify.theme.dark ? 'background' : ''
    }  mx-auto rounded-lg pt-6 pb-3`"
    :style="`${
      $vuetify.theme.dark ? 'border: 1px solid #424242 !important' : ''
    }`"
    height="255"
    width="100%"
    min-width="200"
    hover
    rounded
    outlined
  >
    <div v-if="icon">
      <v-icon
        size="65"
        :class="`d-flex justify-center align-start mb-3`"
        color="accent"
        v-text="icon"
      />
    </div>

    <div v-if="logo" :class="`d-flex justify-center align-start mb-1`">
      <div class="ma-1" style="max-width: 70px">
        <Logo
          v-if="logo"
          :product="logo"
          height="65"
          width="65"
          :white="$vuetify.theme.dark"
          noMargins
        />
      </div>
    </div>

    <h3 class="text-center mx-4">
      {{ $ml.get(title) }}
      <v-chip
        v-if="beta"
        color="success"
        dark
        title="Disponível por tempo limitado!"
        x-small
        class="font-weight-medium"
        >Beta
      </v-chip>
    </h3>

    <v-card-subtitle class="pb-0 mx-2 text-center">
      {{ $ml.get(description) }}
    </v-card-subtitle>
  </v-card>
</template>
<script>
import Logo from "@/components/base/Logo";
export default {
  components: { Logo },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    icon: { type: [String, Boolean], default: false },
    logo: { type: [String, Boolean], default: false },
    type: { type: String, default: "" },
    action: { type: String, default: "" },
    beta: { type: Boolean, default: false },
  },
  methods: {
    execute(action_type, action) {
      if (action_type === "to") {
        this.$router.push(action);
      } else if (action_type === "href") {
        window.open(action, "_blank");
      }
    },
  },
};
</script>
