<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row
      :class="`align-center ma-0 px-1`"
      align-content="center"
      justify-lg="start"
    >
      <v-expand-x-transition v-for="(card, i) in allCards" :key="i">
        <v-col xs="1" md="3" lg="3" xl="2">
          <ItemCard
            :title="card.title"
            :description="card.description"
            :icon="card.icon"
            :logo="card.logo"
            :type="card.type"
            :action="card.action"
            :beta="card.beta"
          />
        </v-col>
      </v-expand-x-transition>
    </v-row>
  </v-container>
</template>

<script>
import ItemCard from "@/components/home/ItemCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "AllReports",

  components: { ItemCard },

  computed: {
    ...mapGetters(["isMicrosoft"]),

    allCards() {
      return [
        {
          icon: "mdi-briefcase-arrow-up-down",
          type: "to",
          action: "/reports/loginlogout",
          title: "reports_loginlogout",
          description: "reports_loginlogout_description",
          show: true,
        },
        {
          icon: "mdi-briefcase-account",
          type: "to",
          action: "/reports/access_duration",
          title: "reports_access_time",
          description: "reports_access_time_description",
          show: true,
        },
        {
          icon: "mdi-account",
          type: "to",
          action: "/reports/users",
          title: "reports_users",
          description: "reports_users_description",
          show: true,
        },
        {
          icon: "mdi-briefcase-clock",
          type: "to",
          action: "/reports/groups",
          title: "reports_groups",
          description: "reports_groups_description",
          show: true,
        },
        {
          icon: "mdi-google-drive",
          type: "to",
          action: "/reports/folders-sharing",
          title: "folders_sharing",
          description: "folders_sharing_description",
          show: !this.isMicrosoft,
          beta: true,
        },
      ].filter((item) => item.show);
    },
  },
};
</script>
